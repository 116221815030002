.hg-button > span {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.small-arrow-buttons {
    max-width:  80px;
}

.right-margin-button {
    margin-right: 85px !important;
}

.kbb {
    pointer-events: visible!important;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.kbb-big-letter {
    color: red;
    font-weight: bold;
    align-self: flex-start;
}

.kbb-big-letter-inactive {
    align-self: flex-start;
}

.kbb-main-text {
    color: #444;
    font-weight: bold;
    white-space: pre-wrap;
    font-size: 10pt;
    flex-grow: 1;
    text-align: center;
}

.kbb-small-text {
    color: #777777;
    font-size: 6pt;
    white-space: pre-wrap;
    align-self: flex-end;
    text-align: right;
    margin-left: -25%; /* TODO - fix this */
}

.kbb-small-text-grow {
    flex-grow: 1;
}

input {
    width: 100%;
    height: 100px;
    padding: 20px;
    font-size: 20px;
    border: none;
    box-sizing: border-box;
}

.keyboardContainer {
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
}

.simple-keyboard.hg-theme-default {
    display: inline-block;
}

.simple-keyboard-main.simple-keyboard {
    width: 75%;
    min-width: 640px;
    background: none;
}

.simple-keyboard-main.simple-keyboard .hg-row:first-child {
    margin-bottom: 10px;
}

.simple-keyboard-arrows.simple-keyboard {
    width: 100%;
    align-self: flex-end;
    background: none;
}

.simple-keyboard .hg-button.hg-button-arrowup {
    width: 33%;
}

.simple-keyboard .hg-button.selectedButton {
    background: rgba(5, 25, 70, 0.53);
    color: white;
}

.simple-keyboard .hg-button.emptySpace {
    pointer-events: none;
    background: none;
    border: none;
    box-shadow: none;
}

.simple-keyboard-arrows .hg-row {
    justify-content: center;
}

.simple-keyboard-arrows .hg-button {
    width: 100%;
    flex-grow: 0;
    justify-content: center;
    display: flex;
    align-items: center;
}

.controlArrows {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column;
}

.simple-keyboard-control.simple-keyboard {
    background: none;
}

.simple-keyboard-control.simple-keyboard .hg-row:first-child {
    margin-bottom: 10px;
}

.simple-keyboard-control .hg-button {
    width: 100%;
    flex-grow: 0;
    justify-content: center;
    display: flex;
    align-items: center;
}

.numPad {
    width: 12%;
    display: flex;
    align-items: flex-end;
}

.simple-keyboard-numpad.simple-keyboard {
    background: none;
}

.simple-keyboard-numpad.simple-keyboard {
    min-width: 140px;
}

.simple-keyboard-numpad.simple-keyboard .hg-button {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.simple-keyboard-numpadEnd.simple-keyboard {
    width: 25%;
    background: none;
    margin: 0;
    padding: 5px 5px 5px 0;
}

.simple-keyboard-numpadEnd.simple-keyboard .hg-button {
    align-items: center;
    justify-content: center;
    display: flex;
}

.simple-keyboard-numpadEnd .hg-button.hg-standardBtn.hg-button-plus {
    height: 85px;
}

.simple-keyboard-numpadEnd.simple-keyboard .hg-button.hg-button-enter {
    height: 85px;
}

.simple-keyboard.hg-theme-default .hg-button.hg-selectedButton {
    background: rgba(5, 25, 70, 0.53);
    color: white;
}

.hg-button.hg-functionBtn.hg-button-space {
    width: 350px;
}
